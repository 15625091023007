import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import PageHeader from "../components/page_header"
import Layout from "../components/layout"
import Img from "gatsby-image"

export const query = graphql`
  query($id: String!) {
    servicePage: wordpressPage(id: { eq: $id }) {
      id
      slug
      title
      acf {
        description
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        services {
          description
          thumbnail_image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 4160) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
  }
`

const Services = ({ data }) => {
  const servicePage = data.servicePage
  const services = servicePage.acf.services
  const meta = servicePage.yoast_meta

  return (
    <Layout>
      <SEO
        title={meta.yoast_wpseo_title}
        description={meta.yoast_wpseo_metadesc}
      />
      <PageHeader
        title={servicePage.title}
        image={servicePage.acf.banner_image}
      />
      <main className="content__container services__wrapper">
        {services.map((service, idx) => (
          <div className="services__item" key={idx}>
            {service.thumbnail_image != null ? (
              <Img
                fluid={service.thumbnail_image.localFile.childImageSharp.fluid}
                className="services__item-img"
                style={{ position: "relative" }}
              />
            ) : (
              ""
            )}
            <div
              className="services__item-description"
              dangerouslySetInnerHTML={{ __html: service.description }}
            />
          </div>
        ))}
      </main>
    </Layout>
  )
}

export default Services
